import { locale as moment_locale, updateLocale as moment_updateLocale } from 'moment';

export function configureMoment(userLocale: string): void {
    moment_updateLocale(userLocale, {
        calendar: {
            sameElse: 'L LT', // Make calendar dates older than a week also show the time.
        },
    });

    // Set the locale moment uses.
    moment_locale(userLocale);
}
