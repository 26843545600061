import { TranslateLoader } from '@ngx-translate/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';

// Using lazy loader instead of http loader to prevent accidental caching
// https://github.com/ngx-translate/http-loader/issues/25#issuecomment-514056865
export class LazyTranslateLoader implements TranslateLoader {
    public translationsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public getTranslation(lang: string): Observable<unknown> {
        return from(import(`../../i18n/${lang}.json`)).pipe(tap(() => this.translationsLoaded$.next(true)));
    }
}
