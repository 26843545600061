import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    window = window;

    public constructor() {}

    ngOnInit(): void {
        // Remove the preload view
        const preloadView = document.getElementById('preload-view');
        if (preloadView) preloadView.remove();
    }
}
