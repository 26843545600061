import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { environment } from '../../environments/environment';
import { Feature } from '../mtp-common/models/feature.model';
import { AuthGuardService } from './guards/auth-guard.service';
import { FeatureGuard } from './guards/feature.guard';
import { NonAuthGuardService } from './guards/non-auth-guard.service';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('../mtp-login/mtp-login.module').then((m) => m.MtpLoginModule),
        canActivate: environment.authGuards ? [NonAuthGuardService] : undefined,
    },
    {
        path: 'map',
        loadChildren: () => import('../mtp-main/mtp-main.module').then((m) => m.MtpMainModule),
        canActivate: environment.authGuards ? [AuthGuardService] : undefined,
    },
    {
        path: 'bulkPlanning',
        loadChildren: () =>
            import('../mtp-bulk-planning/mtp-bulk-planning.module').then((m) => m.MtpBulkPlanningModule),
        canActivate: environment.authGuards ? [AuthGuardService] : undefined,
    },
    {
        path: 'shared/:tenantId/:shareId',
        loadChildren: () =>
            import('../mtp-shared-entity/mtp-shared-entity.module').then((m) => m.MtpSharedEntityModule),
    },
    {
        path: 'dev',
        loadChildren: () => import('../mtp-dev-portal/mtp-dev-portal.module').then((m) => m.MtpDevPortalModule),
        canActivate: environment.authGuards ? [FeatureGuard.forFeature(Feature.DevPortal)] : undefined,
    },
    {
        path: 'reporting',
        loadChildren: () => import('../mtp-reporting/mtp-reporting.module').then((m) => m.MtpReportingModule),
        canActivate: environment.authGuards ? [AuthGuardService] : undefined,
    },
    {
        path: 'history',
        loadChildren: () => import('../mtp-history/mtp-history.module').then((m) => m.MtpHistoryModule),
        canActivate: environment.authGuards ? [AuthGuardService] : undefined,
    },
    {
        path: 'settings',
        loadChildren: () => import('../mtp-settings/mtp-settings.module').then((m) => m.MtpSettingsModule),
        canActivate: environment.authGuards ? [AuthGuardService] : undefined,
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
        }),
    ],
    providers: [FeatureGuard],
    exports: [RouterModule],
})
export class MtpRoutingModule {}
