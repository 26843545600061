import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppStateService } from '../../mtp-common/services/state/app-state.service';
import { TenantService } from '../../mtp-common/services/tenant.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    public constructor(
        private readonly appState: AppStateService,
        private readonly tenantService: TenantService,
        private readonly router: Router
    ) {}

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
        return !this.appState.loggedInWithTenant.value ? this.router.createUrlTree(['login']) : true;
    }
}
