import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppStateService } from '../../mtp-common/services/state/app-state.service';

@Injectable({
    providedIn: 'root',
})
export class NonAuthGuardService implements CanActivate {
    public constructor(private readonly appState: AppStateService, private readonly router: Router) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
        return this.appState.loggedInWithTenant.value ? this.router.createUrlTree(['map']) : true;
    }
}
