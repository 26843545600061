import { TranslateService } from '@ngx-translate/core';
import 'moment/locale/nl.js';
import { firstValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppSettingsService } from '../mtp-common/services/app-settings.service';
import { AppStateService } from '../mtp-common/services/state/app-state.service';
import { configureMoment } from '../utils/moment-utils';

export function AppInitializerFactory(
    translate: TranslateService,
    appSettingsService: AppSettingsService,
    appState: AppStateService
) {
    return async (): Promise<void> => {
        // Load app settings
        await appSettingsService.initializeApp();
        // Set translation defaults
        translate.setDefaultLang(environment.fallbackLocale);

        environment.supportedLocales.forEach((locale) => translate.reloadLang(locale));

        return firstValueFrom(
            appState.user.stream.pipe(
                switchMap((user) => {
                    const userLocale =
                        user?.profile?.language || localStorage.getItem('USER_LANGUAGE') || environment.defaultLocale;

                    configureMoment(userLocale);
                    return translate.use(userLocale);
                }),
                map(() => undefined)
            )
        );
    };
}
